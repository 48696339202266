import React from "react";
import { FaChevronRight } from "react-icons/fa6";
import {
  GoogleAuthProvider,
  GithubAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../config/firebase.config";
import { useNavigate } from "react-router-dom";

const AuthButtonWitchProvider = ({ Icon, label, provider }) => {
  const googleAuthProvider = new GoogleAuthProvider();
  const githubAuthProvider = new GithubAuthProvider();
  const navigate = useNavigate();

  const handleClick = async () => {
    try {
      let result;
      switch (provider) {
        case "GoogleAuthProvider":
          result = await signInWithPopup(auth, googleAuthProvider);
          break;
        case "GithubAuthProvider":
          result = await signInWithPopup(auth, githubAuthProvider);
          break;
        default:
          result = await signInWithPopup(auth, googleAuthProvider);
          break;
      }
      console.log(result);
      navigate("/home", { replace: true });
    } catch (err) {
      console.log(`Erreur: ${err.message}`);
    }
  };

  return (
    <div
      onClick={handleClick}
      className="w-full px-4 py-3 rounded-md border-2 border-blue-700 flex items-center justify-between cursor-pointer group hover:bg-blue-700 active:scale-90 duration-150 hover:shadow-md"
    >
      <Icon className="text-textPrimary text-xl group-hover:text-white" />

      <p className="text-textPrimary text-lg group-hover:text-white">{label}</p>

      <FaChevronRight className="text-textPrimary text-base group-hover:text-white" />
    </div>
  );
};

export default AuthButtonWitchProvider;
