/* eslint-disable react/style-prop-object */
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import "./form.css";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
//import jsPDF from 'jspdf';
import { useReactToPrint } from "react-to-print";
import { ChevronLeft } from "@material-ui/icons";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
export const Multistep = () => {
  //set variables
  const [comname, setcomname] = useState("");
  const [adress, setadress] = useState("");
  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [website, setwebsite] = useState("");
  const [ceoname, setceoname] = useState("");
  const [company, setcompany] = useState("");
  const [employee, setemployee] = useState("");
  //business info
  const [month, setmonth] = useState("");
  const [year, setyear] = useState("");
  const [legalinfo, setlegalinfo] = useState("");
  const [biztype, setbiztype] = useState("");
  //past performance
  const [revenue, setrevenue] = useState("");
  const [profit, setprofit] = useState("");
  const [balance, setbalance] = useState("");
  //product/service
  const [productService, setproductService] = useState("");
  const [currencySymbol, setcurrencySymbol] = useState("");
  const [year1, setyear1] = useState("");
  const [year2, setyear2] = useState("");
  const [year3, setyear3] = useState("");
  //revenue
  const [yr1, setyr1] = useState("");
  const [yr2, setyr2] = useState("");
  const [yr3, setyr3] = useState("");
  //problem solved
  const [problem, setproblem] = useState("");
  const [solution, setsolution] = useState("");
  //marketing
  const [targetcustomers, settargetcustomers] = useState("");
  const [advantage, setadvantage] = useState("");
  const [objective, setobjective] = useState("");
  const [step1, setstep1] = useState("");
  const [step2, setstep2] = useState("");
  const [step3, setstep3] = useState("");
  //finance
  const [startcash, setstartcash] = useState();
  const [payable, setpayable] = useState();
  const [receivable, setreceivable] = useState();
  const [accpayable, setaccpayable] = useState();
  const [percentage, setpercentage] = useState();
  const [year11, setyear11] = useState(Number);
  const [year12, setyear12] = useState(Number);
  const [year13, setyear13] = useState(Number);
  //contributions, loans and draws
  const [year21, setyear21] = useState(Number);
  const [year22, setyear22] = useState(Number);
  const [year23, setyear23] = useState(Number);
  //draws
  const [year31, setyear31] = useState();
  const [year32, setyear32] = useState();
  const [year33, setyear33] = useState();
  const [loanamount, setloanamount] = useState();
  const [interest, setinterest] = useState();
  const [month1, setmonth1] = useState();
  const [loanyear, setloanyear] = useState();
  //asset
  const [assetname, setassetname] = useState("");
  const [cost, setcost] = useState();
  const [asset1, setasset1] = useState();
  const [cost1, setcost1] = useState();
  const [asset2, setasset2] = useState();
  const [cost2, setcost2] = useState();

  //addition
  const draws = parseInt(year31) + parseInt(year32) + parseInt(year33);
  const cashin1 = parseInt(yr1) + parseInt(year21);
  const cashin2 = parseInt(yr2) + parseInt(year22);
  const cashin3 = parseInt(yr3) + parseInt(year23);
  const cashout1 =
    parseInt(cost1) +
    parseInt(cost2) +
    parseInt(cost) +
    parseInt(year31) +
    parseInt(year11) +
    parseInt(year1);
  const cashout2 =
    parseInt(cost1) +
    parseInt(cost2) +
    parseInt(cost) +
    parseInt(year32) +
    parseInt(year12) +
    parseInt(year2);
  const cashout3 =
    parseInt(cost1) +
    parseInt(cost2) +
    parseInt(cost) +
    parseInt(year33) +
    parseInt(year13) +
    parseInt(year3);

  const contri = parseInt(year21) + parseInt(year22) + parseInt(year23);
  const cashbal1 =
    parseInt(year21) + parseInt(loanamount) + parseInt(startcash);
  const cashbal2 =
    parseInt(year22) + parseInt(loanamount) + parseInt(startcash);
  const cashbal3 =
    parseInt(year23) + parseInt(loanamount) + parseInt(startcash);
  const netcash1 = cashin1 - cashout1;
  const netcash2 = cashin2 - cashout2;
  const netcash3 = cashin3 - cashout3;

  const totalexpense = parseInt(year11) + parseInt(year12) + parseInt(year13);
  const totalasset = parseInt(cost) + parseInt(cost1) + parseInt(cost2);
  const COGS = parseInt(year1) + parseInt(year2) + parseInt(year3);
  const totalrevenue = parseInt(yr1) + parseInt(yr2) + parseInt(yr3);
  const gross = parseInt(totalrevenue) - parseInt(COGS);
  const avegross = parseInt(gross) / 3;
  const averevenue = parseInt(totalrevenue) / 3;
  const grossmargin = (parseInt(avegross) / parseInt(averevenue)) * 100;

  const gross1 = parseInt(yr1) - parseInt(year1);
  const gross2 = parseInt(yr2) - parseInt(year2);
  const gross3 = parseInt(yr3) - parseInt(year3);
  const net1 = parseInt(yr1) - parseInt(year11);
  const net2 = parseInt(yr2) - parseInt(year12);
  const net3 = parseInt(yr3) - parseInt(year13);

  const [formStep, setformStep] = useState(0);
  const {
    register,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const completeformStep = () => {
    setformStep((cur) => cur + 1);
  };
  const previousStep = () => {
    setformStep((cur) => cur - 1);
  };

  const MAX_STEPS = 9;

  //marg page
  const getPageMargins = () => {
    const marginTop = 5;
    const marginRight = 5;
    const marginBottom = 25;
    const marginLeft = 5;
    return `@page { margin: ${marginTop}  ${marginRight}  ${marginBottom}  ${marginLeft} !important; }`;
  };
  //chart
  const data = {
    labels: ["Année 1", "Année  2", "Année 3"],
    datasets: [
      {
        label: "Prévisions de ventes",
        data: [[yr1], [yr2], [yr3]],
        backgroundColor: ["pink", "green", "red"],
        borderColor: ["blue", "purple", "black"],
        borderWidth: 1,
      },
    ],
  };

  const d1 = {
    labels: ["Année 1", "Année  2", "Année 3"],
    datasets: [
      {
        label: "Profit et perte",
        data: [[net1], [net2], [net3]],
        backgroundColor: ["pink", "green", "red"],
        borderColor: ["blue", "purple", "black"],
        borderWidth: 1,
      },
    ],
  };

  //pdf generator,
  const componentRef = useRef();

  const print = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div className="container-fliud" style={{ backgroundColor: "lightblue" }}>
        {formStep < MAX_STEPS && (
          <section>
            <div className="formheading">
              <h4>Rédigez votre business plan avec bpminutes</h4>
              <small>
                <p>C'est facile et ne cout que 50 000 fcfa</p>
              </small>
              <p>Remplissez le formulaire ci-dessous et générez votre plan</p>
            </div>
            <hr />
          </section>
        )}
        {formStep < MAX_STEPS && (
          <section>
            <form className="form-group">
              <br />
              <br />
              <div className="container-lg" id="maincontainer">
                <center>
                  {formStep < MAX_STEPS && (
                    <p id="max" className="row">
                      {formStep > 0 && (
                        <p className="col-sm-2">
                          {" "}
                          <button onClick={previousStep} type="button">
                            <ChevronLeft></ChevronLeft>{" "}
                          </button>
                        </p>
                      )}
                      <p className="col-sm-8">
                        <h6>
                          Étape {formStep + 1} de {MAX_STEPS}
                        </h6>
                      </p>
                    </p>
                  )}
                </center>
                {formStep === 0 && (
                  <section>
                    <center>
                      <div className="sample-form">
                        <center>
                          <h4
                            style={{ fontFamily: "sans-serif " }}
                            className="head1"
                          >
                            <b>COORDONNÉES DE LA SOCIÉTÉ</b>
                          </h4>
                        </center>
                        <br />
                        <br />
                        <input
                          type="text"
                          name="comname"
                          {...register("comname", {
                            required: {
                              value: true,
                              message: "Veuillez remplir le champ",
                            },
                          })}
                          placeholder="Nom de l'entreprise"
                          className="form-control"
                          value={comname}
                          onChange={(e) => {
                            setcomname(e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        {errors.comname && <p>{errors.comname.message}</p>}
                        <input
                          type="text"
                          name="adress"
                          {...register("adress", {
                            required: {
                              value: true,
                              message: "Veuillez remplir le champ",
                            },
                          })}
                          placeholder="Adresse de la société"
                          className="form-control"
                          value={adress}
                          onChange={(e) => {
                            setadress(e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <input
                          type="text"
                          name="city"
                          {...register("city", {
                            required: {
                              value: true,
                              message: "Veuillez remplir le champ",
                            },
                          })}
                          placeholder="Ville"
                          className="form-control"
                          value={city}
                          onChange={(e) => {
                            setcity(e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <input
                          type="text"
                          name="country"
                          placeholder="Pays"
                          className="form-control"
                          value={country}
                          onChange={(e) => {
                            setcountry(e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <input
                          type="text"
                          name="phone"
                          placeholder="Téléphone"
                          className="form-control"
                          value={phone}
                          onChange={(e) => {
                            setphone(e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <input
                          type="text"
                          name="email"
                          placeholder="Email"
                          className="form-control"
                          value={email}
                          onChange={(e) => {
                            setemail(e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <input
                          type="url"
                          name="website"
                          placeholder="Site web"
                          className="form-control"
                          value={website}
                          onChange={(e) => {
                            setwebsite(e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <input
                          type="text"
                          name="ceoname"
                          placeholder="Nom du directeur (PDG)"
                          className="form-control"
                          value={ceoname}
                          onChange={(e) => {
                            setceoname(e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <select
                          name="company"
                          id="company"
                          className="form-control"
                          value={company}
                          onChange={(e) => {
                            setcompany(e.target.value);
                          }}
                        >
                          <option value="startup">
                            Type de l'entreprise :
                          </option>
                          <option value="startup"></option>
                          <option value="startup">Start-Up</option>
                          <option value="growth">
                            Petit et moyen entreprise
                          </option>
                          <option value="expansion">grand entreprise </option>
                        </select>
                        <br />
                        <br />
                        <center>
                          <input
                            type="number"
                            name="employee"
                            placeholder="Nombre d'employés"
                            className="form-control"
                            value={employee}
                            onChange={(e) => {
                              setemployee(e.target.value);
                            }}
                          />
                          <br />
                          <br />
                          <button
                            type="button"
                            className="new-btn"
                            onClick={completeformStep}
                          >
                            SUIVANTE
                          </button>
                          <br />
                          <br />
                        </center>
                      </div>
                    </center>
                  </section>
                )}
                {formStep === 1 && (
                  <section>
                    <div className="sample-form">
                      <center>
                        <h4
                          style={{ fontFamily: "sans-serif " }}
                          className="head1"
                        >
                          <b>INFORMATIONS D'AFFAIRES</b>
                        </h4>
                      </center>
                      <br />
                      <br />
                      <p>* Mois et année de démarrage de l'entreprise</p>
                      <enter>
                        {" "}
                        <select
                          id="month"
                          name="month"
                          className="form-control"
                          value={month}
                          onChange={(e) => {
                            setmonth(e.target.value);
                          }}
                        >
                          <option value="startMonth">
                            Selectioner le mois de démarrage
                          </option>
                          <option value="jan">Janvier</option>
                          <option value="feb">Février</option>
                          <option value="mar">Mars</option>
                          <option value="april">Avril</option>
                          <option value="may">Mai</option>
                          <option value="june">Juin</option>
                          <option value="july">Juillet</option>
                          <option value="aug">Août</option>
                          <option value="sep">Septembre</option>
                          <option value="oct">Octobre</option>
                          <option value="nov">Novembre</option>
                          <option value="dec">Décembre</option>
                        </select>
                        <br />
                        <br />
                        <br />
                        <select
                          id="year"
                          name="year"
                          className="form-control"
                          value={year}
                          onChange={(e) => {
                            setyear(e.target.value);
                          }}
                        >
                          <option value="startMonth">
                            Selectioner l'année de démarrage
                          </option>
                          <option value="2025">2025</option>
                          <option value="2024">2024</option>
                          <option value="2023">2023</option>
                          <option value="2022">2022</option>
                          <option value="2021">2021</option>
                        </select>
                      </enter>
                      <br />
                      <br />
                      <div>
                        <p>* Information légale</p>
                        <enter>
                          {" "}
                          <select
                            name="legalInfo"
                            className="form-control"
                            value={legalinfo}
                            onChange={(e) => {
                              setlegalinfo(e.target.value);
                            }}
                          >
                            <option value=""></option>
                            <option value="entreprise individuelle">
                              Entreprise individuelle
                            </option>
                            <option value="société à responsabilité limitée">
                              Société à responsabilité limitée
                            </option>
                            <option value="GIE">GIE</option>
                            <option value="société anonyme">
                              société anonyme
                            </option>
                          </select>
                        </enter>
                      </div>
                      <br />
                      <br />
                      <div>
                        <p>
                          * L'entreprise fournit-elle un service,
                          fabrique-t-elle produits ou les deux ?
                        </p>
                        <center>
                          <select
                            id="biztype"
                            name="biztype"
                            className="form-control"
                            value={biztype}
                            onChange={(e) => {
                              setbiztype(e.target.value);
                            }}
                          >
                            <option value=""></option>
                            <option value="fournisseur de services">
                              fournisseur de services
                            </option>
                            <option value="Fabricant de produits">
                              fabricant de produits
                            </option>
                            <option value="fournisseur de services et fabricant de produits">
                              Les deux
                            </option>
                          </select>
                        </center>
                      </div>
                      <br />
                      <br />
                      <label>
                        <p>*Quel problème votre entreprise résout-elle ?</p>
                      </label>
                      <center>
                        <textarea
                          row="60"
                          col="50"
                          name="problem"
                          className="form-control"
                          value={problem}
                          onChange={(e) => {
                            setproblem(e.target.value);
                          }}
                        ></textarea>
                      </center>
                      <br /> <br />
                      <label>
                        <p>* Comment résout-il le problème ?</p>
                      </label>
                      <center>
                        {" "}
                        <textarea
                          row="60"
                          col="50"
                          name="solution"
                          className="form-control"
                          value={solution}
                          onChange={(e) => {
                            setsolution(e.target.value);
                          }}
                        ></textarea>
                      </center>
                      <br /> <br />
                      <center>
                        <button
                          type="button"
                          className="new-btn"
                          onClick={completeformStep}
                        >
                          SUIVANTE
                        </button>
                      </center>
                    </div>
                  </section>
                )}

                {formStep === 2 && (
                  <section>
                    <div className="sample-form">
                      <center>
                        <h4 className="head1">
                          <b>PERFORMANCES PASSÉES</b>
                        </h4>
                        <br />
                        <br />
                        <p>
                          <small>
                            (Cette section n'apparaîtra pas si l'entreprise est
                            une startup)
                          </small>
                        </p>
                        <input
                          type="text"
                          name="revenue"
                          va
                          placeholder="Revenu"
                          className="form-control"
                          value={revenue}
                          onChange={(e) => {
                            setrevenue(e.target.value.replace(/\D/g, ""));
                          }}
                        />
                        <br />
                        <br />

                        <input
                          type="text"
                          name="profit"
                          placeholder="Bénéfice net"
                          className="form-control"
                          value={profit}
                          onChange={(e) => {
                            setprofit(e.target.value.replace(/\D/g, ""));
                          }}
                        />
                        <br />
                        <br />

                        <input
                          type="text"
                          name="balance"
                          placeholder="Solde de trésorerie"
                          className="form-control"
                          value={balance}
                          onChange={(e) => {
                            setbalance(e.target.value.replace(/\D/g, ""));
                          }}
                        />
                        <br />
                        <br />
                        <button
                          type="button"
                          className="new-btn"
                          onClick={completeformStep}
                        >
                          SUIVANTE
                        </button>
                      </center>
                    </div>
                  </section>
                )}
                {formStep === 3 && (
                  <section>
                    <div className="sample-form">
                      <center>
                        {" "}
                        <h4 className="head1">
                          <b>PRODUIT/SERVICE</b>
                        </h4>
                      </center>
                      <br />
                      <br />
                      <p>* Ajouter une offre de produit/service</p>
                      <center>
                        <input
                          type="text"
                          name="productService"
                          placeholder="Product/service"
                          className="form-control"
                          value={productService}
                          onChange={(e) => {
                            setproductService(e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <input
                          type="text"
                          name="currencySymbol"
                          className="form-control"
                          placeholder="Symbole de la monnaie"
                          value={currencySymbol}
                          onChange={(e) => {
                            setcurrencySymbol(e.target.value);
                          }}
                        />
                        <br />
                        <br />
                      </center>
                      <p>* Coût des biens</p>
                      <center>
                        <input
                          type="number"
                          name="year1"
                          placeholder="Année 1"
                          className="form-control"
                          value={year1}
                          onChange={(e) => {
                            setyear1(+e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <input
                          type="number"
                          name="year2"
                          placeholder="Année 2"
                          className="form-control"
                          value={year2}
                          onChange={(e) => {
                            setyear2(+e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <input
                          type="number"
                          name="year3"
                          placeholder="Année 3"
                          className="form-control"
                          value={year3}
                          onChange={(e) => {
                            setyear3(+e.target.value);
                          }}
                        />
                        <br />
                        <br />
                      </center>
                      <p>* Revenus attendus</p>
                      <center>
                        <input
                          type="number"
                          name="yr1"
                          placeholder="Année 1"
                          className="form-control"
                          value={yr1}
                          onChange={(e) => {
                            setyr1(+e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <input
                          type="number"
                          name="yr2"
                          placeholder="Année 2"
                          className="form-control"
                          value={yr2}
                          onChange={(e) => {
                            setyr2(+e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <input
                          type="number"
                          name="yr3"
                          placeholder="Année 3"
                          className="form-control"
                          value={yr3}
                          onChange={(e) => {
                            setyr3(+e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <button
                          type="button"
                          className="new-btn"
                          onClick={completeformStep}
                        >
                          SUIVANTE
                        </button>
                      </center>
                    </div>
                  </section>
                )}

                {formStep === 4 && (
                  <section>
                    <div className="sample-form">
                      <center>
                        <h4 className="head1">
                          <b>FRAIS DE MARKETING ET CLIENTS CIBLES</b>
                        </h4>
                      </center>
                      <br />
                      <br />
                      <p>*Qui sont vos clients cibles ?</p>
                      <center>
                        <textarea
                          row="60"
                          col="50"
                          name="targetcustomers"
                          className="form-control"
                          value={targetcustomers}
                          onChange={(e) => {
                            settargetcustomers(e.target.value);
                          }}
                        ></textarea>
                      </center>
                      <br />
                      <br />
                      <p>* Avantage compétitif</p>
                      <center>
                        {" "}
                        <textarea
                          row="60"
                          col="50"
                          name="advantage"
                          className="form-control"
                          value={advantage}
                          onChange={(e) => {
                            setadvantage(e.target.value);
                          }}
                        ></textarea>
                      </center>
                      <br />
                      <br />
                      <p>
                        <label for="objective">
                          *Quel est l'objectif de votre entreprise
                        </label>
                      </p>
                      <center>
                        <textarea
                          row="60"
                          col="50"
                          name="objective"
                          value={objective}
                          onChange={(e) => {
                            setobjective(e.target.value);
                          }}
                          placeholder="Objective"
                          className="form-control"
                        ></textarea>
                      </center>{" "}
                      <br />
                      <br />
                      <p>
                        * Entrez les étapes stratégiques pour atteindre
                        l'objectif commercial
                      </p>
                      <center>
                        <input
                          type="text"
                          name="step1"
                          placeholder="La première étape"
                          className="form-control"
                          value={step1}
                          onChange={(e) => {
                            setstep1(e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <input
                          type="text"
                          name="step2"
                          placeholder="La Deuxième étape"
                          className="form-control"
                          value={step2}
                          onChange={(e) => {
                            setstep2(e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <input
                          type="text"
                          name="step3"
                          placeholder="La Troisième étape"
                          className="form-control"
                          value={step3}
                          onChange={(e) => {
                            setstep3(e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <button
                          type="button"
                          className="new-btn"
                          onClick={completeformStep}
                        >
                          SUIVANTE
                        </button>
                      </center>
                    </div>
                  </section>
                )}
                {formStep === 5 && (
                  <section>
                    <div className="sample-form">
                      <center>
                        <h4 className="head1">
                          <b>APERÇU FINANCIER</b>
                        </h4>
                      </center>
                      <br />
                      <br />
                      <p>
                        * Solde de trésorerie de l'entreprise au moment du
                        démarrage de l'entreprise
                      </p>
                      <center>
                        <input
                          type="number"
                          name="startcash"
                          className="form-control"
                          value={startcash}
                          onChange={(e) => {
                            setstartcash(+e.target.value);
                          }}
                          required
                        />
                      </center>
                      <br />
                      <br />
                      <p>
                        * Combien de jours attendez-vous pour payer vos factures
                        (compte payable)
                      </p>
                      <center>
                        {" "}
                        <input
                          type="number"
                          name="payable"
                          className="form-control"
                          value={payable}
                          onChange={(e) => {
                            setpayable(+e.target.value);
                          }}
                          required
                        />
                      </center>
                      <br />
                      <br />
                      <p>
                        * Jours de crédit accordés aux clients (compte à
                        recevoir)
                      </p>
                      <center>
                        {" "}
                        <input
                          type="number"
                          name="receivable"
                          className="form-control"
                          value={receivable}
                          onChange={(e) => {
                            setreceivable(+e.target.value);
                          }}
                          required
                        />
                      </center>
                      <br />
                      <br />
                      <p>* Crédit de jours accordé par le fournisseur</p>
                      <center>
                        {" "}
                        <input
                          type="number"
                          name="accpayable"
                          className="form-control"
                          value={accpayable}
                          onChange={(e) => {
                            setaccpayable(+e.target.value);
                          }}
                          required
                        />
                      </center>
                      <br />
                      <br />
                      <p>
                        * Pourcentage de clients à qui vous accordez un crédit %
                      </p>
                      <center>
                        <input
                          type="percentage"
                          name="percentage"
                          className="form-control"
                          value={percentage}
                          onChange={(e) => {
                            setpercentage(e.target.value);
                          }}
                          required
                        />
                      </center>
                      <br />
                      <br />
                      <p>* Montant total dépensé en dépenses chaque année</p>
                      <center>
                        <input
                          type="number"
                          name="year11"
                          placeholder="Première année"
                          className="form-control"
                          value={year11}
                          onChange={(e) => {
                            setyear11(e.target.value);
                          }}
                          required
                        />
                        <br />
                        <br />
                        <input
                          type="number"
                          name="year12"
                          placeholder="Deuxième année"
                          className="form-control"
                          value={year12}
                          onChange={(e) => {
                            setyear12(e.target.value);
                          }}
                          required
                        />
                        <br />
                        <br />
                        <input
                          type="number"
                          name="year13"
                          placeholder="Troisième année"
                          className="form-control"
                          value={year13}
                          onChange={(e) => {
                            setyear13(e.target.value);
                          }}
                          required
                        />
                        <br />
                        <br />
                        <button
                          type="button"
                          className="new-btn"
                          onClick={completeformStep}
                        >
                          SUIVANTE
                        </button>
                        <br />
                        <br />
                      </center>
                    </div>
                  </section>
                )}
                {formStep === 6 && (
                  <section>
                    <div className="sample-form">
                      <center>
                        <h4 className="head1">
                          <b>CONTRIBUTIONS, PRÊTS ET TIRAGES</b>
                        </h4>
                      </center>
                      <br />
                      <br />
                      <p>
                        * Quel montant en espèces les propriétaires versent-ils
                        chaque année ?
                      </p>
                      <center>
                        <input
                          type="number"
                          name="year21"
                          placeholder="Première année"
                          className="form-control"
                          value={year21}
                          onChange={(e) => {
                            setyear21(+e.target.value);
                          }}
                          required
                        />
                        <br />
                        <br />
                        <input
                          type="number"
                          name="year22"
                          placeholder="Deuxième année"
                          className="form-control"
                          value={year22}
                          onChange={(e) => {
                            setyear22(+e.target.value);
                          }}
                          required
                        />
                        <br />
                        <br />
                        <input
                          type="number"
                          name="year23"
                          placeholder="Troisième année"
                          className="form-control"
                          value={year23}
                          onChange={(e) => {
                            setyear23(+e.target.value);
                          }}
                          required
                        />
                        <br />
                        <br />
                      </center>
                      <p>
                        * Combien d'argent le propriétaire retire-t-il chaque
                        année ?
                      </p>
                      <center>
                        <input
                          type="number"
                          name="year31"
                          placeholder="Première année"
                          className="form-control"
                          value={year31}
                          onChange={(e) => {
                            setyear31(+e.target.value);
                          }}
                          required
                        />
                        <br />
                        <br />
                        <input
                          type="number"
                          name="year32"
                          placeholder="Deuxième année"
                          className="form-control"
                          value={year32}
                          onChange={(e) => {
                            setyear32(+e.target.value);
                          }}
                          required
                        />
                        <br />
                        <br />
                        <input
                          type="number"
                          name="year33"
                          placeholder="Troisième année"
                          className="form-control"
                          value={year33}
                          onChange={(e) => {
                            setyear33(+e.target.value);
                          }}
                          required
                        />
                        <br />
                        <br />
                      </center>
                      <h6>Détails du prêt</h6>
                      <center>
                        <input
                          type="number"
                          name="loanamount"
                          placeholder="Montant total du prêt"
                          className="form-control"
                          value={loanamount}
                          onChange={(e) => {
                            setloanamount(+e.target.value);
                          }}
                          required
                        />
                      </center>
                      <br />
                      <br />
                      <p>* Type d'intérêt</p>
                      <center>
                        <select
                          name="interest"
                          className="form-control"
                          value={interest}
                          onChange={(e) => {
                            setinterest(e.target.value);
                          }}
                          required
                        >
                          <option value=""></option>
                          <option value="Simple">Simple</option>
                          <option value="compound">Composé</option>
                        </select>
                        <br />
                        <br />
                      </center>
                      <p>* Quand comptez-vous recevoir le fonds ?</p>
                      <br />
                      <p>. Mois</p>
                      <center>
                        <select
                          name="month1"
                          className="form-control"
                          value={month1}
                          onChange={(e) => {
                            setmonth1(e.target.value);
                          }}
                          required
                        >
                          <option value="startMonth"></option>
                          <option value="Januay">Janvier</option>
                          <option value="Februry">Février</option>
                          <option value="March">Mars</option>
                          <option value="April">Avril</option>
                          <option value="May">Mai</option>
                          <option value="June">Juin</option>
                          <option value="July">Juillet</option>
                          <option value="August">Août</option>
                          <option value="September">Septembre</option>
                          <option value="October">Octobre</option>
                          <option value="November">Novembre</option>
                          <option value="December">Décembre</option>
                        </select>
                        <br />
                      </center>
                      <p>. Année</p>
                      <center>
                        <select
                          name="loanyear"
                          className="form-control"
                          value={loanyear}
                          onChange={(e) => {
                            setloanyear(e.target.value);
                          }}
                          required
                        >
                          <option value="startMonth"></option>
                          <option value="2025">2025</option>
                          <option value="2024">2024</option>
                          <option value="2023">2023</option>
                          <option value="2022">2022</option>
                          <option value="2021">2021</option>
                        </select>
                      </center>
                      <br />
                      <br />
                      <center>
                        <button
                          type="button"
                          className="new-btn"
                          onClick={completeformStep}
                        >
                          SUIVANTE
                        </button>
                      </center>
                    </div>
                  </section>
                )}

                {formStep === 7 && (
                  <section>
                    <div className="sample-form">
                      <center>
                        <h4 className="head1">
                          <b>VOS ACTIFS</b>
                        </h4>
                      </center>
                      <br />
                      <br />

                      <center>
                        <input
                          type="text"
                          name="assetname"
                          placeholder="Nom de l'actif (exemple: table,chaise,ordinateu..)"
                          className="form-control"
                          value={assetname}
                          onChange={(e) => {
                            setassetname(e.target.value);
                          }}
                        />
                      </center>
                      <br />
                      <br />

                      <center>
                        <input
                          type="number"
                          name="cost"
                          placeholder="Le Coût total de vos actifs (exemple: table,chaise,ordinateu..)"
                          className="form-control"
                          value={cost}
                          onChange={(e) => {
                            setcost(+e.target.value);
                          }}
                        />
                      </center>
                      <br />
                      <br />
                      <h6>Autre actif</h6>
                      <p>
                        * Atout un exemple : avoir acces gratuitement d'un
                        service
                      </p>
                      <cnter>
                        <input
                          type="text"
                          name="asset1"
                          id="asset1"
                          placeholder="Nom"
                          className="form-control"
                          value={asset1}
                          onChange={(e) => {
                            setasset1(e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <input
                          type="number"
                          name="cost1"
                          id="cost1"
                          placeholder="Coût"
                          className="form-control"
                          value={cost1}
                          onChange={(e) => {
                            setcost1(+e.target.value);
                          }}
                        />
                      </cnter>
                      <br />
                      <br />
                      <p>* Atout deux</p>
                      <center>
                        <input
                          type="text"
                          name="asset2"
                          placeholder="Nom"
                          className="form-control"
                          value={asset2}
                          onChange={(e) => {
                            setasset2(e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <input
                          type="number"
                          name="cost2"
                          id="cost2"
                          placeholder="Coût"
                          className="form-control"
                          value={cost2}
                          onChange={(e) => {
                            setcost2(+e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <button
                          type="submit"
                          className="new-btn"
                          onClick={completeformStep}
                        >
                          SUIVANTE
                        </button>
                      </center>
                      <br />
                      <br />
                    </div>
                  </section>
                )}

                {formStep === 8 && (
                  <section>
                    <center>
                      <b>
                        <h4 className="head1">Vérifiez vos informations</h4>
                      </b>
                    </center>
                    <br />
                    <hr />
                    <p>Coordonnées de l'entreprise</p>
                    <Link to="">Modifier</Link>
                    <div className="row" id="row1">
                      <div className="col-md 6">
                        <p>
                          Nom de l'entreprise
                          <br />
                          <b>{comname}</b>
                        </p>
                        <p>
                          Adresse de la société
                          <br />
                          <b>{adress}</b>
                        </p>
                        <p>
                          Ville
                          <br />
                          <b>{city}</b>
                        </p>
                        <p>
                          Pays
                          <br />
                          <b>{country}</b>
                        </p>
                        <p>
                          Telephone
                          <br />
                          <b>{phone}</b>
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p>
                          E-mail
                          <br />
                          <b>{email}</b>
                        </p>
                        <p>
                          Site web
                          <br />
                          <b>{website}</b>
                        </p>
                        <p>
                          Nom du PDG
                          <br />
                          <b>{ceoname}</b>
                        </p>
                        <p>
                          Type de la société
                          <br />
                          <b>{company}</b>
                        </p>
                        <p>
                          Nombre d'employés
                          <br />
                          <b>{employee}</b>
                        </p>
                      </div>
                    </div>
                    <p>Informations d'affaires</p>
                    <Link to="">Modifier</Link>
                    <div className="row align-items-start" id="row1">
                      <div className="col">
                        <p>
                          Information légale
                          <br />
                          <b>{legalinfo}</b>
                        </p>
                      </div>
                      <div className="col">
                        <p>
                          L'entreprise fournit-elle un service, fabrique-t-elle
                          des produits ou les deux?
                          <br />
                          <b>{biztype}</b>
                        </p>
                      </div>
                    </div>
                    <p>Produit/Service</p>
                    <Link to="">Modifier</Link>
                    <div className="row" id="row1">
                      <div className="col-md 6">
                        <p>
                          Produit/Service
                          <br />
                          <b>{productService}</b>
                        </p>
                        <p>
                          Symbole de la monnaie
                          <br />
                          <b>{currencySymbol}</b>
                        </p>
                        <p>Coût des biens</p>
                        <p>
                          {" "}
                          Première année <br />
                          <b>{year1}</b>
                        </p>
                        <p>
                          Deuxième année
                          <br />
                          <b>{year2}</b>
                        </p>
                      </div>
                      <div className="col-md 6">
                        <p>
                          Troisième année
                          <br />
                          <b>{year3}</b>
                        </p>
                        <p>Revenus attendus</p>
                        <p>
                          Première année
                          <br />
                          {yr1}
                        </p>
                        <p>
                          Deuxième année
                          <br />
                          {yr2}
                        </p>
                        <p>
                          Troisième année
                          <br />
                          {yr3}
                        </p>
                      </div>
                    </div>
                    <p>Dépenses marketing et clients cibles</p>
                    <div className="row" id="row1">
                      <div className="col-md 6">
                        <p>
                          Qui sont vos clients cibles ?
                          <br />
                          {targetcustomers}
                        </p>
                        <p>
                          Avantage compétitif
                          <br />
                          {advantage}
                        </p>
                        <p>
                          Objective
                          <br />
                          {objective}
                        </p>
                      </div>
                      <div className="col-md 6">
                        <p>
                          Étapes stratégiques pour atteindre l’objectif
                          commercial
                        </p>
                        <p>
                          la première étape
                          <br />
                          {step1}
                        </p>
                        <p>
                          la deuxième étape
                          <br />
                          {step2}
                        </p>
                        <p>
                          la troisième étape
                          <br />
                          {step3}
                        </p>
                      </div>
                    </div>
                    <b>
                      <p>Aperçu financier</p>
                      <Link to="">Edit</Link>
                    </b>
                    <div className="row" id="row1">
                      <div className="col-md 6">
                        <p>
                          Solde de trésorerie de l'entreprise au moment du
                          démarrage de votre activité
                          <br />
                          {startcash}
                        </p>
                        <p>
                          Combien de jours faut-il attendre pour payer les
                          factures ?
                          <br />
                          {payable}
                        </p>
                        <p>
                          Crédit de jours accordé au client
                          <br />
                          {receivable}
                        </p>
                        <p>
                          Crédit de jours accordé par le fournisseur
                          <br />
                          {accpayable}
                        </p>
                      </div>
                      <div className="col-md 6">
                        <p>
                          Pourcentage de clients à qui vous accordez un crédit
                          <br />
                          <b>{percentage}</b>
                        </p>
                        <p>Montant total dépensé en dépenses chaque année</p>
                        <p>
                          la Première année
                          <br />
                          {year11}
                        </p>
                        <p>
                          la Deuxième année
                          <br />
                          {year12}
                        </p>
                        <p>
                          la Troisième année
                          <br />
                          {year13}
                        </p>
                      </div>
                    </div>
                    <br />
                    <center>
                      {" "}
                      <button
                        type="button"
                        className="new-btn"
                        onClick={completeformStep}
                      >
                        Voir le forfait
                      </button>
                    </center>
                    <br />
                    <br />
                    <br />
                  </section>
                )}
              </div>
            </form>
            <br />
            <br />
          </section>
        )}

        {formStep === 9 && (
          <section>
            <center>
              <br />
              <br />
              <div className="container-lg" id="textplan">
                <div
                  id="content"
                  className="print-agreement"
                  ref={componentRef}
                >
                  <style> {getPageMargins()} </style>
                  <h3>{comname}</h3>
                  <p>Adresse:{adress}</p>
                  <p>Ville: {city}</p>
                  <p>Pays: {country}</p>
                  <p>Tél: {phone}</p>
                  <p>E-mail:{email}</p>
                  <p>Site web: {website}</p>
                  <p>PDG: {ceoname}</p>
                  <br />
                  <br />
                  <br />
                  <p>Plan d'affaires sur trois ans</p>
                  <br />
                  <br />
                  <br />
                  <br />
                  <p>
                    <h4>Résumé exécutif</h4>
                    <p>
                      {comname} offres les produits et services suivant :{" "}
                      {productService} aux {targetcustomers} a {city}.
                      L'entreprise jouit d'une position solide sur le marché et
                      d'un stratégie cohérente. Il a établi des mesures claires
                      pour atteindre son objectif de {objective} dans les trois
                      prochains années.
                    </p>
                    <p>
                      {comname} a fixé des objectifs qu'ils s'engagent à
                      atteindre au cours des trois prochaines années. La
                      prévision du chiffre d'affaires de l'entreprise est {yr1}
                      {currencySymbol} la première année, {yr2}
                      {currencySymbol} en deuxième année, et {yr3}
                      {currencySymbol} en troisième année. Dès la dernière année
                      de ce plan, l'entreprise réalisera un bénéfice net de{" "}
                      {currencySymbol}. Cela représentera un bon retour et
                      prévoir une réserve suffisante pour les futurs plans de
                      développement. La date de début de ce business plan est{" "}
                      {month} {year}.
                    </p>
                  </p>
                  <p>
                    <h4>Les affaires</h4>
                    <p>
                      {comname} est un {company} affaires avec {employee}{" "}
                      employés qui fonctionne comme un {biztype} et c'est la
                      forme comme un {legalinfo} forme d'entreprise. C'est un
                      structure juridique appropriée pour ce type d’entreprise
                      et correspondra bien à ses objectifs. L'entreprise
                      appartient a {ceoname}, qui a de l'expérience dans cette
                      industrie. Le PDG et son équipe de direction reverront
                      cette structure au fur et à mesure du développement de
                      l'entreprise.
                    </p>
                    <p>
                      {comname} est bien placé pour offrir les produits et
                      services suivant: {productService} a {city}. La direction
                      possède l’expérience et les compétences nécessaires pour
                      atteindre l’objectif qu’elle s’est fixé.
                    </p>
                    <p>
                      L'équipe de direction s'engage à développer l'entreprise
                      en fournissant un excellent service à la clientèle et en
                      créant un marque forte et crédible. Cela sera démontré à
                      travers leurs relations de confiance avec les clients et
                      autres parties prenantes. L'équipe de direction prendra
                      ses décisions responsabilité sérieuse et engagée dans une
                      approche éthique aux affaires.
                    </p>
                    <p>
                      En résumé,{comname} a la forme juridique appropriée,
                      ressources, valeurs et équipe de direction pour réussir.
                      Le L'effet combiné de ces facteurs fournit une forte
                      fondation pour réaliser une entreprise de redressement et
                      de profit.
                    </p>
                  </p>

                  <p>
                    <h4>Le marché</h4>
                    <p>
                      {comname} a l'intention de cibler {targetcustomers} avec
                      son {productService}. Ce marché représente un bon
                      opportunité pour l'entreprise de capitaliser sur ses{" "}
                      {advantage} avantage compétitif. Cela fournira un position
                      forte de l'entreprise sur le marché.
                    </p>
                    <p>
                      {comname} a pu découvrir ses principaux concurrents et
                      leurs faiblesses sur ce marché attractif. Cette volonté
                      restreindre sa capacité à rivaliser. {comname} sera
                      capable d'exploiter les faiblesses de ses concurrents pour
                      gagner position sur le marché.
                    </p>
                    <p>
                      En résumé, la forte demande pour le {comname} offre et les
                      faiblesses identifiées chez ses principaux concurrents
                      permettent une opportunité de marché attractive.
                    </p>
                  </p>

                  <p>
                    <h4>Stratégie de marketing</h4>
                    <p>
                      {comname} a un objectif de {objective} au cours du
                      prochain trois ans. L'équipe de direction a identifié les
                      étapes clés pour atteindre l'objectif triennal et
                      exploiter les opportunités identifiées, cela sera
                      systématiquement mis en œuvre sur la période. Certaines
                      des clés identifiées les étapes sont :
                      <br />.{step1}
                      <br />.{step2}
                      <br />.{step3}
                      <br />
                      La sélection et l'enchaînement de ces actions ont été
                      soigneusement choisi pour produire le plein potentiel du
                      opportunité commerciale et assurez-vous que la cible est
                      atteint. L'équipe de direction surveillera en permanence
                      la performance de l'entreprise par rapport aux objectifs
                      et faire ajustements nécessaires. À tout moment, l'accent
                      sera mis atteindre les objectifs clés.
                    </p>
                    <p>
                      En résumé, {comname} a clairement identifié les
                      opportunités et un plan systématique avec des étapes
                      clairement articulées pour atteindre son objectif de trois
                      ans.
                    </p>
                  </p>

                  <p>
                    <h4>Prévisions de ventes</h4>
                    <p>
                      Les prévisions de ventes indiquent que la croissance sera
                      lente mais constant. La croissance sera lente à cause du
                      temps et des efforts nécessaire pour développer la
                      clientèle. Production ou service n'est pas l'élément
                      ralentisseur comme l'équipe de direction de {comname} a
                      expérience dans cette industrie.
                    </p>
                    <p>
                      Il existe quelques risques qui pourraient avoir un impact
                      négatif sur ventes. L’un de ces risques est l’inflation.
                      Un taux élevé de l’inflation entraînera une baisse des
                      dépenses de consommation et une baisse des ventes aussi.
                      {comname} est capable de minimiser ces risques autant que
                      possible.
                    </p>
                    <p>
                      <h6>Tableau : Prévisions de ventes</h6>
                      <br />
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Ventes</th>
                            <th>Année 1</th>
                            <th>Année 2</th>
                            <th>Année 3</th>
                          </tr>
                          <tr>
                            <td>{productService}</td>
                            <td>
                              {yr1}
                              {currencySymbol}
                            </td>
                            <td>
                              {yr2}
                              {currencySymbol}
                            </td>
                            <td>
                              {yr3}
                              {currencySymbol}
                            </td>
                          </tr>
                          <tr>
                            <td>Total</td>
                            <td>
                              {yr1}
                              {currencySymbol}
                            </td>
                            <td>
                              {yr2}
                              {currencySymbol}
                            </td>
                            <td>
                              {yr3}
                              {currencySymbol}
                            </td>
                          </tr>
                        </thead>
                      </table>
                    </p>
                    <Chart
                      type="bar"
                      data={data}
                      height={100}
                      width={150}
                      options={{
                        maintainAspectRation: false,
                      }}
                    />
                  </p>
                  <br />

                  <p>
                    <h4>Prévisions financières</h4>
                    <p>
                      {comname} a un cash de départ de {startcash}
                      {currencySymbol}. Les propriétaires prévoient de
                      contribuer {contri}
                      {currencySymbol}
                      en capital et souscrire {draws}
                      {currencySymbol} en tirages.
                    </p>
                    <p>
                      Les comptes créditeurs sont établis {payable} jours,
                      tandis que le compte les créances sont fixées à
                      {receivable} jours.
                      {comname} prévoit que {percentage} sera à crédit.
                    </p>
                    <p>
                      L'entreprise bénéficie d'un prêt d'un montant total de la
                      dette de {loanamount}
                      {currencySymbol}. En outre,
                      {comname} est en train de prévoir {totalexpense}
                      {currencySymbol} dans les dépenses totales et {totalasset}
                      {currencySymbol} au cours des trois premières années
                      d'exploitation.
                    </p>
                    <p>
                      Toutes les prévisions du plan d’affaires utilisent les
                      régles de la comptabilité .
                    </p>
                    <p>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Dépenses</th>
                            <th>Année 1</th>
                            <th>Année 2</th>
                            <th>Année 3</th>
                          </tr>
                        </thead>
                        <tr>
                          <td>Dépenses totales</td>
                          <td>
                            {year11}
                            {currencySymbol}
                          </td>
                          <td>
                            {year12}
                            {currencySymbol}
                          </td>
                          <td>
                            {year13}
                            {currencySymbol}
                          </td>
                        </tr>
                        <tr></tr>
                      </table>
                    </p>
                    <br />
                    <p>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Immobilisation</th>
                            <th>Coût</th>
                          </tr>
                        </thead>
                        <tr>
                          <td>{assetname}</td>
                          <td>
                            {cost}
                            {currencySymbol}
                          </td>
                        </tr>
                        <tr>
                          <td>{asset1}</td>
                          <td>
                            {cost1}
                            {currencySymbol}
                          </td>
                        </tr>
                        <tr>
                          <td>{asset2}</td>
                          <td>
                            {cost2}
                            {currencySymbol}
                          </td>
                        </tr>
                        <tr></tr>
                      </table>
                    </p>
                    <br />
                    <p>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Contributions et tirages des propriétaires</th>
                            <th>Année 1</th>
                            <th>Année 2</th>
                            <th>Année 3</th>
                          </tr>
                        </thead>
                        <tr>
                          <td>Contributions</td>
                          <td>
                            {year21}
                            {currencySymbol}
                          </td>
                          <td>
                            {year22}
                            {currencySymbol}
                          </td>
                          <td>
                            {year23}
                            {currencySymbol}
                          </td>
                        </tr>
                        <tr>
                          <td>Tirages</td>
                          <td>
                            {year31}
                            {currencySymbol}
                          </td>
                          <td>
                            {year32}
                            {currencySymbol}
                          </td>
                          <td>
                            {year33}
                            {currencySymbol}
                          </td>
                        </tr>
                        <tr></tr>
                      </table>
                    </p>
                    <br />
                    <p>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Prêt</th>
                            <th>Montant</th>
                          </tr>
                        </thead>
                        <tr>
                          <td>Capital</td>
                          <td>
                            {loanamount}
                            {currencySymbol}
                          </td>
                        </tr>
                        <tr></tr>
                      </table>
                    </p>
                    <br />
                  </p>
                  <p>
                    <h4>Profit et perte</h4>
                    <p>
                      {comname} prévoit le bénéfice brut pour la première année
                      de opérations à {gross1}
                      {currencySymbol}, augmentant jusqu'à {gross2}
                      {currencySymbol} la deuxième année et augmentant jusqu'à{" "}
                      {gross3}
                      {currencySymbol} en troisième année.
                    </p>
                    <p>La marge brute moyenne est de {grossmargin} %.</p>
                    <p>
                      Le bénéfice net après toutes dépenses, taxes comprises,
                      est projeté à {net1}
                      {currencySymbol} la première année,
                      {net2}
                      {currencySymbol} en deuxième année et {net3}
                      {currencySymbol} en troisième année. Les propriétaires
                      envisagent de tirer un tirage ou un dividende de {year31}
                      {currencySymbol} la première année,
                      {year32}
                      {currencySymbol} en deuxième année, et {year33}
                      {currencySymbol} en troisième année.
                    </p>
                    <p>
                      <table className="table">
                        <thead>
                          <tr></tr>
                          <tr>
                            <th>Prévisions de profits et pertes</th>
                            <th>Année 1</th>
                            <th>Année 2</th>
                            <th>Année 3</th>
                          </tr>
                        </thead>
                        <tr>
                          <td>
                            <b>Revenu</b>
                            <br />
                            Ventes
                            <br />
                            COGS
                            <br />
                            Bénéfice brut
                          </td>
                          <td>
                            <br />
                            {yr1}
                            {currencySymbol}
                            <br />
                            {year1}
                            {currencySymbol}
                            <br />
                            {gross1}
                            {currencySymbol}
                          </td>
                          <td>
                            <br />
                            {yr2}
                            {currencySymbol}
                            <br />
                            {year2}
                            {currencySymbol}
                            <br />
                            {gross2}
                            {currencySymbol}
                          </td>
                          <td>
                            <br />
                            {yr3}
                            {currencySymbol}
                            <br />
                            {year3}
                            {currencySymbol}
                            <br />
                            {gross3}
                            {currencySymbol}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Total</b>
                            <br />
                            Dépenses de fonctionnement totales
                            <br />
                            Tirages du propriétaire/dividende
                            <br />
                            Bénéfice net
                          </td>
                          <td>
                            <br />
                            {year11}
                            {currencySymbol}
                            <br />
                            {year31}
                            {currencySymbol}
                            <br />
                            {net1}
                            {currencySymbol}
                            <br />
                          </td>
                          <td>
                            <br />
                            {year12}
                            {currencySymbol}
                            <br />
                            {year32}
                            {currencySymbol}
                            <br />
                            {net2}
                            {currencySymbol}
                            <br />
                          </td>
                          <td>
                            <br />
                            {year13}
                            {currencySymbol}
                            <br />
                            {year33}
                            {currencySymbol}
                            <br />
                            {net3}
                            {currencySymbol}
                            <br />
                          </td>
                        </tr>
                        <tr></tr>
                      </table>
                    </p>
                    <Chart
                      type="bar"
                      data={d1}
                      height={100}
                      width={150}
                      options={{
                        maintainAspectRation: false,
                      }}
                    />
                  </p>
                  <br />
                  <p>
                    <h4>Des flux de trésorerie</h4>
                    <p>
                      Les propriétaires ont investi un total de {contri}
                      {currencySymbol} et avez ou recherchez des prêts soit un
                      total de {loanamount}
                      {currencySymbol}. {comname} prévoit un flux de trésorerie
                      net.
                    </p>
                    <p>
                      <table className="table">
                        <thead>
                          <tr></tr>
                          <tr>
                            <th>Prévisions de flux de trésorerie</th>
                            <th>Année 1</th>
                            <th>Année 2</th>
                            <th>Année 3</th>
                          </tr>
                        </thead>
                        <tr>
                          <td>
                            <b>Encaisser</b>
                            <br />
                            Ventes <br />
                            Contributions
                            <br />
                            Encaissement total
                          </td>
                          <td>
                            {yr1}
                            {currencySymbol}
                            <br />
                            {year21}
                            {currencySymbol}
                            <br />
                            {cashin1}
                            {currencySymbol}
                          </td>
                          <td>
                            {yr2}
                            {currencySymbol}
                            <br />
                            {year22}
                            {currencySymbol}
                            <br />
                            {cashin2}
                            {currencySymbol}
                          </td>
                          <td>
                            {yr3}
                            {currencySymbol}
                            <br />
                            {year23}
                            {currencySymbol}
                            <br />
                            {cashin3}
                            {currencySymbol}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Encaissement</b>
                            <br />
                            COGS <br />
                            Espèces payées pour les actifs
                            <br />
                            Dépenses <br />
                            Les propriétaires tirent au sort <br />
                            Encaissement total
                          </td>
                          <td>
                            {year1}
                            {currencySymbol}
                            <br />
                            {totalasset}
                            {currencySymbol}
                            <br />
                            {year11}
                            {currencySymbol}
                            <br />
                            {year31}
                            {currencySymbol}
                            <br />
                            {cashout1}
                            {currencySymbol}
                          </td>
                          <td>
                            {year2}
                            {currencySymbol}
                            <br />
                            {totalasset}
                            {currencySymbol} <br />
                            {year12}
                            {currencySymbol}
                            <br />
                            {year32}
                            {currencySymbol}
                            <br />
                            {cashout2}
                            {currencySymbol}
                          </td>
                          <td>
                            {year3}
                            {currencySymbol}
                            <br />
                            {totalasset}
                            {currencySymbol} <br />
                            {year13}
                            {currencySymbol}
                            <br />
                            {year33}
                            {currencySymbol}
                            <br />
                            {cashout3}
                            {currencySymbol}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Trésorerie et solde nets</b>
                            <br />
                            Solde de trésorerie de départ
                            <br />
                            Solde de trésorerie de clôture
                          </td>
                          <td>
                            {cashbal1}
                            {currencySymbol}
                            <br />
                            {netcash1}
                            {currencySymbol}
                            <br />
                          </td>
                          <td>
                            {cashbal2}
                            {currencySymbol}
                            <br />
                            {netcash2}
                            {currencySymbol}
                            <br />
                          </td>
                          <td>
                            {cashbal3}
                            {currencySymbol}
                            <br />
                            {netcash3}
                            {currencySymbol}
                            <br />
                          </td>
                        </tr>
                        <tr></tr>
                      </table>
                    </p>
                  </p>
                  <br />
                  <br />
                  <br />
                </div>
                <center>
                  <button style={{ width: "100px" }} onClick={print}>
                    Télécharger le votre business plan (BP) en PDF
                  </button>
                </center>
              </div>
              <br />
              <br />
              <br />
            </center>
          </section>
        )}
      </div>
    </>
  );
};
export default Multistep;
